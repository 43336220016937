.Header {
  z-index: 10;
  position: relative;
}
.Header .header-tabs {
  /* position: absolute; */
}
.Header .spacer {
  flex-grow: 1;
}

.Header .burger-menu-svg {
  height: 2rem;
}
.Header .mobile-menu-btn {
  margin-left: 0.25rem;
  margin-top: 2px;
}
.Header .night {
  background-color: #2c2e33;
}
.Header .day {
  background-color: #00afbd;
}
#mobile-menu {
  margin-top: -4px;
  margin-left: -4px;
  background-color: transparent;
}
#mobile-menu .MuiPaper-root {
  background-color: #00afbd;
}
#mobile-menu .MuiButtonBase-root {
  /* background-color: #00afbd; */
  /* color: #2c2e33; */
}
#mobile-menu.night .MuiPaper-root {
  background-color: #2c2e33;
}
#mobile-menu.day {
  /* background-color: #00afbd; */
  background-color: transparent;
}
