.HomePage {
  /* background-color: #00afbd; */
  /* background: linear-gradient(180deg, #00afbd 0%, #00afbd 20%, #2c2e33 100%); */
  background-color: #2c2e33;
  transition: all 0.2s;
}
.HomePage .social-icon img {
  box-shadow: 0 4px 4px rgb(0, 0, 0, 0.25);
  border-radius: 50%;
}
.HomePage .video-art-wrapper {
  text-align: center;
}
.HomePage .contact-art-wrapper {
  text-align: center;
  margin-top: 2rem;
}
.HomePage .contact-footer {
  text-align: center;
  margin-top: 2rem;
}
.HomePage .contact-footer a button {
  margin-right: 2rem;
}
.HomePage .gradient {
  height: 8rem;
  background: linear-gradient(180deg, #00afbd 0%, #2c2e33 100%);
}
.HomePage a {
  color: #f5c500;
}
.HomePage .container {

}
.HomePage .container h4 {
  margin-top: 2rem;
  font-weight: 600;
}
.HomePage .container h6 {
  margin-top: 1rem;
  font-weight: 600;
}
.HomePage .container .tourney-list {
  margin: 0;
}
.HomePage .container .tourney-list li p {
  margin: 0;
}
.HomePage .container p {
  margin-top: 0rem;
  margin-bottom: 1rem;
}
.HomePage .container {
  padding: 3rem;
  padding-bottom: 8rem;
  position: relative;
  z-index: 4;
}
.HomePage .backdrop {
  position: absolute;
  top: 0px;
  z-index: 2;
  left: 0;
  width: 100%;
}
.HomePage .backdrop-wrapper {
  overflow: hidden;
  width: 100%;
  height: calc(100% + 10rem);
  position: absolute;
  top: 0rem;
  /* top: -2rem; */
}
.HomePage .splash .splash-text {
  z-index: 3;
  position: absolute;
  top: calc(25vw - 6rem);
  left: calc(15% - 10rem);
  /* left: 5%; */
  width: 48rem;
  text-align: center;
  padding: 4rem;
}
.HomePage .splash .splash-text h3 {
  font-weight: 700;
}
.HomePage .splash .socials {
  margin-top: 1.5rem;
}

.HomePage .splash {
  height: 90vh;
  z-index: 1;
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
}
@media only screen and (max-width: 1000px) {
  .HomePage .backdrop-wrapper {
    position: relative;
  }
  .HomePage .backdrop {
    right: -200px;
    left: auto;
    width: calc(100% + 200px);
  }
}
@media only screen and (max-width: 880px) {
  .HomePage .backdrop {
    right: -400px;
    left: auto;
    width: calc(100% + 400px);
  }
  .HomePage .splash .splash-text {
    top: 10rem;
  }
}
@media only screen and (max-width: 630px) {
  .HomePage .backdrop {
    right: auto;
    width: 100%;
    position: relative;
  }
  .HomePage .splash {
    height: auto;
    overflow: hidden;
  }
  .HomePage .splash .splash-text {
    width: 100%;
    left: 0;
    top: 0rem;
    padding: 0.5rem;
    position: relative;
  }
  .HomePage .video-art {
    width: 100%;
  }
}
