.Sponsors2022Page {
  padding: 2rem;
  background-color: #2c2e33;
  min-height: 100vh;
}

.Sponsors2022Page h3 {
  font-weight: 700;
}
.Sponsors2022Page .sponsor-card {
  margin-bottom: 4rem;
}
.Sponsors2022Page .sponsor-card .logo-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}
.Sponsors2022Page .sponsor-card .logo-wrapper img {
  max-width: 14rem;
  max-height: 14rem;
  background-color: white;
}
.Sponsors2022Page .sponsor-card .company-name {
  margin-bottom: 1rem;
}
.Sponsors2022Page .sponsor-card .company-name span {
  font-weight: 500;
  font-size: 2.2rem;
  color: #f9efe2;
}
.Sponsors2022Page .sponsor-card .company-name .tier {
  margin-left: 1.5rem;
  font-size: 1.4rem;
}
@media only screen and (max-width: 1000px) {
  .Sponsors2022Page .sponsor-card .company-name {
    text-align: center;
  }
  .Sponsors2022Page .sponsor-card .company-name .tier {
    display: block;
    margin: 0;
  }
  .Sponsors2022Page .sponsor-card .logo-wrapper {
    margin: auto;
  }
}
.Sponsors2022Page .sponsor-card .company-name .tier.Gold {
  color: #eec53a;
}
.Sponsors2022Page .sponsor-card .company-name .tier.Bronze {
  color: brown;
}
.Sponsors2022Page .sponsor-card .company-desc a {
  color: #9bbcff;
}
.Sponsors2022Page .sponsor-card .company-desc a:hover {
  text-decoration: underline;
}
.Sponsors2022Page .sponsors-wrapper {
  margin-top: 4rem;
}
