.SpecsPage {
  /* background-color: #00afbd; */
  /* background: linear-gradient(180deg, #00afbd 0%, #00afbd 20%, #2c2e33 100%); */
  background-color: #2c2e33;
  transition: all 0.2s;
  padding: 3rem;
  min-height: 100vh;
  /* padding-bottom: calc(100vh - 4rem); */
}
.SpecsPage .container {
  /* background-image: url('./stars.svg'); */
}
.SpecsPage .container .specs-grid {
  flex-grow: 1;
}
.SpecsPage .countdown {
  font-size: 8rem;
  text-align: center;
  margin-top: 4rem;
}
.SpecsPage .anchor {
  display: block;
  position: relative;
  z-index: -1;
  top: -55px;
}
.SpecsPage img {
  max-width: calc(100vw - 8rem);
}
.SpecsPage h2 {
  font-weight: 500;
  font-size: 3.5rem;
}
.SpecsPage p {
  margin-top: 0.5rem;
}
.SpecsPage h3 {
  margin-top: 2rem;
  font-weight: 500;
  font-size: 2.5rem;
}
.SpecsPage h4 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 500;
}

.SpecsPage table {
  margin-top: 1rem;
  /* border-collapse: collapse; */
  /* background-color: #f9efe2; */
  border-spacing: 2px;
  color: #f9efe1;
  /* color: rgba(51, 56, 68, 1); */
}
.SpecsPage table tr:nth-child(1) > td:nth-child(2) {
  border-top-left-radius: 25px;
}
.SpecsPage table tr:nth-child(1) > td:last-child {
  border-top-right-radius: 25px;
}
.SpecsPage table tr:first-child > td:first-child {
  background-color: #5f5282;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.SpecsPage table tr > td:first-child {
  background-color: #5c528e;
}
.SpecsPage table tr:last-child > td:first-child {
  border-bottom-left-radius: 25px;
}
.SpecsPage table tr:last-child > td:nth-child(2) {
  /* border-bottom-left-radius: 25px; */
}
.SpecsPage table tr:last-child > td:last-child {
  border-bottom-right-radius: 25px;
}
.SpecsPage table tr:first-child > td {
  font-weight: 500;
  font-size: 1.2rem;
  /* border-bottom: solid 4px #2c2e33; */
  padding: 1rem;
  background-color: #2273ab;
}
.SpecsPage table td {
  border: solid 2px #2c2e33;
  text-align: center;
  background-color: #6f7ca6;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.SpecsPage table tr:first-child td {
  /* padding-bottom: 1rem; */
}
.SpecsPage p {
  font-size: 1rem;
}
.SpecsPage a {
  color: #f5c500;
}

.SpecsPage .toc-bar {
  border-left: 4px solid #f9efe1;
  position: sticky;
  top: 2rem;
  padding-left: 0.5rem;
}
.SpecsPage .toc-bar .toc-item {
  color: #f9efe1;
}
.SpecsPage .toc-bar .toc-text span {
  font-size: 0.95rem;
}
.SpecsPage .toc-bar .toc-item > div {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  height: auto;
}
.SpecsPage .toc-bar .toc-item.active .toc-text span {
  font-weight: 700;
}
.SpecsPage .toc-bar .toc-item.active > div::before {
  background-color: #2c2e33;
  content: '';
  position: absolute;
  width: 22px;
  left: -21px;
  height: 22px;
  border-radius: 100%;
}
.SpecsPage .toc-bar .toc-item.active > div::after {
  background-color: #1a65ff;
  content: '';
  position: absolute;
  width: 12px;
  left: -16px;
  height: 12px;
  border-radius: 100%;
}
@media only screen and (max-width: 1000px) {
  .SpecsPage {
    padding: 1.5rem;
  }
  .SpecsPage h2 {
    font-size: 2.5rem;
  }
  .SpecsPage table {
  }
}
